import React from 'react';

const SecondPage = () => {
  return (
    <div
      style={{
        marginTop: '20%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <h3>
        You found my easter egg! I love people who are able to find things like
        this, shoot me an email at{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="mailto:vincentbocCS@gmail.com"
        >
          vincentbocCS@gmail.com
        </a>{' '}
        to let me know you found it!
      </h3>
    </div>
  );
};

export default SecondPage;
